import React from "react"
import BackgroundImage from "gatsby-background-image-es5"

class BlogHero extends React.Component {
  render() {
    const { heroImage, heroTitle } = this.props
    return (
      <div className="relative">
        <BackgroundImage
          fluid={heroImage}
          style={{ backgroundPosition: "center center" }}
        >
          <div
            className="text-white font-hairline flex flex-col items-center justify-center"
            style={{ height: "620px" }}
          >
            <div className="text-center" style={{ maxWidth: "1200px" }}>
              <h1 className="font-hairline text-3xl mb-5">{heroTitle}</h1>
            </div>
          </div>
        </BackgroundImage>
      </div>
    )
  }
}

export default BlogHero
