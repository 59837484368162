import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PortableText from "@sanity/block-content-to-react"
import imageUrlBuilder from "@sanity/image-url"
import "./blogStyle.css"
import BlogHero from "../components/BlogHero"
import SyntaxHighlighter from "react-syntax-highlighter"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"

const urlFor = source =>
  imageUrlBuilder({ projectId: "v32r25bv", dataset: "production" }).image(
    source
  )

const serializer = {
  types: {
    figure: props => (
      <div className="w-full flex justify-center">
        <img
          src={urlFor(props.node.image.asset).width(700).url()}
          alt={props.node.image.alt}
          className="my-12"
        />
      </div>
    ),
    listItem: props => (
      <li style={{ paddingBottom: "", marginLeft: "30px", marginTop: "5px" }}>
        {props.children}
      </li>
    ),
    code: props => {
      const { code } = props.node
      if (!code) {
        return null
      }
      return (
        <SyntaxHighlighter language={props.node.language || "text"}>
          {code}
        </SyntaxHighlighter>
      )
    },
  },
}

export const query = graphql`
  query($slug: String!) {
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      releaseDate(formatString: "MMMM Do, YYYY")
      author {
        name
        _rawBio
        image {
          asset {
            fluid(maxHeight: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawContent
      excerpt
      slug {
        current
      }
      featuredImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const short = props.data.sanityBlogPost
  console.log(short.excerpt)
  return (
    <Layout>
      <SEO
        title={short.title}
        description={short.excerpt}
        url={`https://www.orangecolormedia.com/${short.slug.current}`}
        image={short.featuredImage.asset.fluid.src}
        // keywords={short.keywords}
      />
      <BlogHero
        heroTitle={short.title}
        heroImage={short.featuredImage.asset.fluid}
      />
      <div className="mt-12 mb-25 font-hairline">
        <div
          className="max-w-twelve mx-auto px-10px pb-12 blogpost"
          style={{ borderBottom: "1px solid #e0e0e0" }}
        >
          <div className="mb-10">
            <p className="mb-1 text-xl">{short.author.name.toUpperCase()}</p>
            <p className="text-green">{short.releaseDate}</p>
          </div>
          <PortableText blocks={short._rawContent} serializers={serializer} />
        </div>
        <h3 className="text-center text-xl mt-12">SHARE POST</h3>
        <div className="flex mt-4 mx-auto justify-center">
          <div className="mr-4">
            <FacebookShareButton
              url={`https://www.orangecolormedia.com/${short.slug.current}`}
            >
              <FaFacebookF size="27px" />
            </FacebookShareButton>
          </div>

          <div className="mr-4">
            <TwitterShareButton
              url={`https://www.orangecolormedia.com/${short.slug.current}`}
            >
              <FaTwitter size="28px" />
            </TwitterShareButton>
          </div>
          <div>
            <LinkedinShareButton
              url={`https://www.orangecolormedia.com/${short.slug.current}`}
              title={short.title}
            >
              <FaLinkedinIn size="28px" />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate
